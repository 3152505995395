/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import ContentRows from '../../../components/page/ContentRows';
import CustomStyleFontFamilyLinks from '../../../components/page/CustomStyleFontFamilyLinks';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import PlayableIframeScript from '../../../components/page/PlayableIframeScript';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.landingPage,
  },
  notifications: {
    marginTop: theme.spacing(4),
  },
});

function LandingPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulLandingPage');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();
  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <CustomStyleFontFamilyLinks pageData={pageData} />
      <PlayableIframeScript pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />

      <main>
        <SiteNotification pageData={pageData} className={classes.notifications} />
        <ContentRows pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

LandingPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

LandingPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query LandingPageTemplate($pageId: String!) {
    contentfulLandingPage(id: { eq: $pageId }) {
      id
      contentful_id
      menuTitle
      shortDescription
      languageCode
      customStyleFontFamilyLinks
      internal {
        type
      }
      searchPage {
        ...SearchPageFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      contentRows {
        ...ContentRowFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      mainTheme {
        ...themeFragment
      }
      alternativeTheme {
        ...themeFragment
      }
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(LandingPageTemplate));
